/* This file should contain All EndPoint Routes Across all the application*/
/* Follow the Naming Convention*/

const prod_uri = "https://milano.journeyopexp.com/v1";
const dev_uri = "http://localhost:3005/v1";

export const uri = process.env.NODE_ENV === "production" ? prod_uri : dev_uri;

export const API_URLS = {
  auth: {
    login: uri + "/auth/login",
    changePassword: uri + "/auth/changePassword",
    resetPassword: uri + "/auth/resetPassword",
    register: uri + "/auth/register",
    logout: uri + "/auth/logout",
    refreshToken: uri + "/auth/refreshToken",
  },
  File: {
    upload: uri + "/file/upload",
    UploadMultiple: uri + "/Files",
    delete: uri + "/file/delete/",
    download: uri + "/file/download/",
    viewPDF: uri + "/file/PDF/",
    getOne: uri + "/file/",
  },
  Assessment: {
    post: uri + "/Assessment",
    get: uri + "/Assessment/all",
    getOne: uri + "/Assessment/{Id}",
    put: uri + "/Assessment/{Id}",
    delete: uri + "/Assessment/{Id}",
  },
  Idea: {
    Submitted: {
      post: uri + "/idea/subscription",
      get: uri + "/idea/subscription/all",
      getOne: uri + "/idea/subscription/{Id}",
      put: uri + "/idea/subscription/{Id}",
      delete: uri + "/idea/subscription/{Id}",
    },
    Approvals: {
      post: uri + "/idea/approval",
      get: uri + "/idea/approval/all",
      getOne: uri + "/idea/approval/{Id}",
      put: uri + "/idea/approval/{Id}",
      delete: uri + "/idea/approval/{Id}",
    },
    CRUD: {
      post: uri + "/idea-crud",
      get: uri + "/idea-crud/all",
      backup: uri + "/idea-crud/backup",
      getOne: uri + "/idea-crud/",
      getIdeasByCode: uri + "/idea-crud/code/",
      put: uri + "/idea-crud/{Id}",
      delete: uri + "/idea-crud/{Id}",
      updateStatus: uri + "/idea-crud/updateStatus/",
      importFile: uri + "/idea-crud/import-file",
      getAllImported: uri + "/idea-crud/allImported",
      getFilteredIdeas: uri + "/idea-crud/all",
      importBackupfile: uri + "/idea-crud/import-data",
    },
    Score: {
      post: uri + "/idea-score",
      getAll: uri + "/idea-score/all",
    },
  },
  Country: {
    post: uri + "/Country",
    get: uri + "/Country/all",
    getOne: uri + "/Country/{Id}",
    put: uri + "/Country/{Id}",
    delete: uri + "/Country/{Id}",
  },
  City: {
    post: uri + "/City",
    get: uri + "/City/all",
    getOne: uri + "/City/{Id}",
    put: uri + "/City/{Id}",
    delete: uri + "/City/{Id}",
  },
  Answer: {
    post: uri + "/Answer",
    get: uri + "/Answer/all",
    getOne: uri + "/Answer/{Id}",
    put: uri + "/Answer/{Id}",
    delete: uri + "/Answer/{Id}",
  },
  AssessmentType: {
    post: uri + "/AssessmentType",
    get: uri + "/AssessmentType/all",
    getOne: uri + "/AssessmentType/{Id}",
    put: uri + "/AssessmentType/{Id}",
    delete: uri + "/AssessmentType/{Id}",
  },
  AssessmentQuestions: {
    post: uri + "/AssessmentQuestions",
    get: uri + "/AssessmentQuestions/all",
    getOne: uri + "/AssessmentQuestions/{Id}",
    put: uri + "/AssessmentQuestions/{Id}",
    delete: uri + "/AssessmentQuestions/{Id}",
  },
  // QuestionGroup: {
  //   post: uri + "/QuestionGroup",
  //   get: uri + "/QuestionGroup/all",
  //   getOne: uri + "/QuestionGroup/{Id}",
  //   put: uri + "/QuestionGroup/{Id}",
  //   delete: uri + "/QuestionGroup/{Id}",
  // },
  Clients: {
    post: uri + "/Clients",
    get: uri + "/Clients/all",
    getOne: uri + "/Clients/{Id}",
    put: uri + "/Clients/{Id}",
    delete: uri + "/Clients/{Id}",
  },
  Company: {
    post: uri + "/company",
    get: uri + "/company/all",
    getOne: uri + "/company/{Id}",
    put: uri + "/company/{Id}",
    delete: uri + "/company/{Id}",
    getNames: uri + "/company/names",
  },
  Industry: {
    post: uri + "/Industry",
    get: uri + "/Industry/all",
    getOne: uri + "/Industry/{Id}",
    put: uri + "/Industry/{Id}",
    delete: uri + "/Industry/{Id}",
  },
  Consultants: {
    post: uri + "/Consultants",
    get: uri + "/Consultants/all",
    getOne: uri + "/Consultants/{Id}",
    put: uri + "/Consultants/{Id}",
    delete: uri + "/Consultants/{Id}",
  },
  Departments: {
    post: uri + "/Departments",
    get: uri + "/Departments/all",
    getOne: uri + "/Departments/{Id}",
    put: uri + "/Departments/{Id}",
    delete: uri + "/Departments/{Id}",
  },
  Facility: {
    post: uri + "/Facility",
    get: uri + "/Facility/all",
    getOne: uri + "/Facility/{Id}",
    put: uri + "/Facility/{Id}",
    delete: uri + "/Facility/{Id}",
  },
  Projects: {
    post: uri + "/Projects",
    get: uri + "/Projects/all",
    getOne: uri + "/Projects/{Id}",
    put: uri + "/Projects/{Id}",
    delete: uri + "/Projects/{Id}",
  },
  JobTitle: {
    post: uri + "/JobTitle",
    get: uri + "/JobTitle/all",
    getOne: uri + "/JobTitle/{Id}",
    put: uri + "/JobTitle/{Id}",
    delete: uri + "/JobTitle/{Id}",
  },
  Location: {
    post: uri + "/Location",
    get: uri + "/Location/all",
    getOne: uri + "/Location/{Id}",
    put: uri + "/Location/{Id}",
    delete: uri + "/Location/{Id}",
  },
  Notification: {
    post: uri + "/Notification",
    get: uri + "/Notification/all",
    getOne: uri + "/Notification/{Id}",
    put: uri + "/Notification/{Id}",
    delete: uri + "/Notification/{Id}",
  },
  User: {
    post: uri + "/User",
    get: uri + "/User/all",
    getOne: uri + "/User/{Id}",
    put: uri + "/User/{Id}",
    delete: uri + "/User/{Id}",
    token: uri + "/User/token",
    updateFcm: uri + "/User​/updateFcm​/{Id}",
    getOneByCode: uri + "/User/code/",
    getUsersCode: uri + "/User/code/all",
  },
  Analytics: {
    ideas: uri + "/analytics/ideas",
    clients: uri + "/analytics/clients",
    projects: uri + "/analytics/projects",
    users: uri + "/analytics/users",
    assessments: uri + "/analytics/assessments",
  },
  Factory: {
    post: uri + "/factory",
    get: uri + "/factory/all",
    getOne: uri + "/factory/{Id}",
    put: uri + "/factory/{Id}",
    delete: uri + "/factory/{Id}",
    getAllById: uri + "/factory/all/{Id}",
  },
  MiniBusiness: {
    post: uri + "/mini-business",
    get: uri + "/mini-business/all",
    getOne: uri + "/mini-business/{Id}",
    put: uri + "/mini-business/{Id}",
    delete: uri + "/mini-business/{Id}",
  },
  Department: {
    post: uri + "/department",
    get: uri + "/department/all",
    getOne: uri + "/department/{Id}",
    put: uri + "/department/{Id}",
    delete: uri + "/department/{Id}",
    getAllById: uri + "/department/all/{Id}",
  },
  Stage: {
    post: uri + "/stage",
    get: uri + "/stage/all",
    getOne: uri + "/stage/{Id}",
    put: uri + "/stage/{Id}",
    delete: uri + "/stage/{Id}",
    getAllById: uri + "/stage/all/{Id}",
  },
  FirstCut: {
    post: uri + "/first-cut",
    get: uri + "/first-cut/all",
    getOne: uri + "/first-cut/{Id}",
    put: uri + "/first-cut/{Id}",
    delete: uri + "/first-cut/{Id}",
    getAllById: uri + "/first-cut/all/{Id}",
  },
  SecondCut: {
    post: uri + "/second-cut",
    get: uri + "/second-cut/all",
    getOne: uri + "/second-cut/{Id}",
    put: uri + "/second-cut/{Id}",
    delete: uri + "/second-cut/{Id}",
    getAllById: uri + "/second-cut/all/{Id}",
  },
  Questions: {
    post: uri + "/question",
    get: uri + "/question/all",
    getOne: uri + "/question/{Id}",
    put: uri + "/question/{Id}",
    delete: uri + "/question/{Id}",
  },
  OfferedAnswers: {
    post: uri + "/offered-answer",
    get: uri + "/offered-answer/all",
    getOne: uri + "/offered-answer/{Id}",
    put: uri + "/offered-answer/{Id}",
    delete: uri + "/offered-answer/{Id}",
  },
  SubAnswers: {
    post: uri + "/sub-answer",
    get: uri + "/sub-answer/all",
    getOne: uri + "/sub-answer/{Id}",
    put: uri + "/sub-answer/{Id}",
    delete: uri + "/sub-answer/{Id}",
  },
  FactoryBusinessUnit: {
    post: uri + "/factory-businessUnit",
    get: uri + "/factory-businessUnit/all",
    backup: uri + "/factory-businessUnit/backup",
    getOne: uri + "/factory-businessUnit/{Id}",
    put: uri + "/factory-businessUnit/{Id}",
    delete: uri + "/factory-businessUnit/{Id}",
    getNames: uri + "/factory-businessUnit/names",
  },
  AreaSection: {
    post: uri + "/area-section",
    get: uri + "/area-section/all",
    backup: uri + "/area-section/backup",
    getOne: uri + "/area-section/{Id}",
    put: uri + "/area-section/{Id}",
    delete: uri + "/area-section/{Id}",
    getAllById: uri + "/area-section/all/{Id}",
  },
  AICMiniBusiness: {
    post: uri + "/AIC-minibusiness",
    get: uri + "/AIC-minibusiness/all",
    backup: uri + "/AIC-minibusiness/backup",
    getOne: uri + "/AIC-minibusiness/{Id}",
    put: uri + "/AIC-minibusiness/{Id}",
    delete: uri + "/AIC-minibusiness/{Id}",
    getAllById: uri + "/AIC-minibusiness/all/{Id}",
  },
  RewardAndRecognition: {
    post: uri + "/rewardand-recognition",
    get: uri + "/rewardand-recognition/all",
    getOne: uri + "/rewardand-recognition/{Id}",
    put: uri + "/rewardand-recognition/{Id}",
    delete: uri + "/rewardand-recognition/{Id}",
  },
  EmployeeCodeSheet: {
    post: uri + "/empployee-code",
    createMany: uri + "/empployee-code/createMany",
    get: uri + "/empployee-code/all",
    getOne: uri + "/empployee-code/",
    put: uri + "/empployee-code/{Id}",
    delete: uri + "/empployee-code/{Id}",
  },
  Video: {
    post: uri + "/video-list",
    get: uri + "/video-list/all",
    getOne: uri + "/video-list/",
    delete: uri + "/video-list/",
  },
  EmployeeSheet: {
    post: uri + "/employee-sheet",
    get: uri + "/employee-sheet/all",
    getOne: uri + "/employee-sheet/{Id}",
    put: uri + "/employee-sheet/{Id}",
    delete: uri + "/employee-sheet/{Id}",
  },
};

/*eslint-disable*/
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import {
  getIdeasCount,
  getUsersCount,
} from "../../store/slices/analytics/analyticsSlice";
import { useAppDispatch, useSelector } from "../../store/store";
import { useTranslation } from "react-i18next";

function CardsHeader({ name, parentName }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { ideas, users } = useSelector((state) => state.analytics);
  useEffect(() => {
    try {
      dispatch(getIdeasCount());
      dispatch(getUsersCount());
    } catch (err) {
      return err;
    }
  }, []);
  return (
    <>
      <div className="header bg-dark pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>

            <Row>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {t("totalIdeas")}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {ideas?.count}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <i className="ni ni-active-40" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {t("users")}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {users?.count}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="ni ni-chart-pie-35" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <iframe
        title="Milano Dashboard"
        width="100%"
        height="1200"
        src="https://app.powerbi.com/view?r=eyJrIjoiYmMwMWMzNGEtZGIxYS00ZTYxLWI4Y2QtMWU2ZGM5NjY2YzdhIiwidCI6IjhkN2UyMGExLTY2YTEtNDE0Yy05YzNjLTZkN2ZlMzA3NzJiYiIsImMiOjh9"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;

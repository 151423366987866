import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, } from "react-redux";
import { Button, Card, CardHeader, CardBody, Container, Row } from "reactstrap";
import confirm from "reactstrap-confirm";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { notify } from "../../../utils/helpers";
import { addRewardAndRecognition, getRewardAndRecognitions } from "../../../store/slices/RewardAndRecognition/RewardAndRecognitionActions";
import { API_URLS } from "../../../constants/API_URLS";

const UploadRewardAndRecognition = () => {
    const pdfFiles = useSelector(
        (state) => state?.RewardAndRecognition?.RewardAndRecognitions
    );
    const { t } = useTranslation();
    const notificationAlertRef = useRef(null);

    const [pdfFile, setPdfFile] = useState(null);
    const dispatch = useDispatch();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setPdfFile(file);
    };
    const msgAlert = (type, msg) => {
        notify(type, msg, notificationAlertRef, t("submittedSuccessfully"));
    }
    const onUpload = async () => {
        let result = await confirm({
            title: <>Warning</>,
            message: t("r&r.uploadfile"),
            confirmText: "Confirm",
            confirmColor: "primary",
            cancelColor: "link text-danger",
        });
        if (result)
            dispatch(addRewardAndRecognition({ file: pdfFile, alertFun: msgAlert, t }));
    }
    useEffect(() => {
        dispatch(getRewardAndRecognitions());
    }, [])

    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <SimpleHeader name={t("idea.ideas")} parentName={t("idea.ideasManagement")} />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">{t("r&r.uploadYourFile")}</h3>
                            </CardHeader>
                            <CardBody >
                                <input type="file" accept=".pdf" onChange={handleFileChange} />
                                <Button type="button" color="primary" onClick={onUpload} >{t("r&r.upload")}</Button>
                            </CardBody>
                        </Card>
                    </div >
                </Row >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                    {pdfFiles[0]?.fileId[0] && (
                        <>
                            <div style={{ width: '80%', maxWidth: '1000px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
                                <embed
                                    style={{ borderRadius: '10px' }}
                                    title="PDF Viewer"
                                    width="100%"
                                    height="1200px"
                                    src={API_URLS.File.viewPDF + pdfFiles[0].fileId[0]}
                                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                />
                            </div>
                        </>
                    )}
                </div>
            </Container >
        </>
    );
};
export default UploadRewardAndRecognition;
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { useDispatch, useSelector, } from "react-redux";
import { Button, Card, CardBody, CardHeader, Container, Row } from "reactstrap";
import confirm from "reactstrap-confirm";
import XLSX from "xlsx";
import { addEmployeeCodeSheet } from "../../../store/slices/EmployeeCodeSheet/EmployeeCodeSheetActions";
import { downloadFile, findEmployeeCodeDuplicates, notify } from "../../../utils/helpers";
import style from "./UploadEmployeeCodeSheet.module.scss";
import { addEmployeeSheet, getEmployeeSheets } from "../../../store/slices/EmployeeSheet/EmployeeSheetActions";
import { API_URLS } from "../../../constants/API_URLS";

const UploadEmployeeCodeSheet = () => {
    const sheet = useSelector(
        (state) => state?.EmployeeSheet?.EmployeeSheets
    );
    const { t } = useTranslation();
    const notificationAlertRef = useRef(null);
    const dispatch = useDispatch();
    const msgAlert = (type, msg) => {
        notify(type, msg, notificationAlertRef);
    }

    const [files, setFiles] = useState([]);
    const [data, setData] = useState([]);

    const handleFileChange = (event) => {
        setFiles(event.target.files[0]);
    };

    const onUpload = async () => {
        if (files) {
            let fileReader = new FileReader();
            fileReader.readAsBinaryString(files);
            fileReader.onload = async (e) => {
                let allRows = [];
                let data = e.target.result;
                let workbook = XLSX.read(data, { type: "binary" });
                workbook.SheetNames.forEach((sheet) => {
                    let row = XLSX.utils.sheet_to_row_object_array(
                        workbook.Sheets[sheet]
                    );

                    allRows.push(...row);
                });
                const duplicates = findEmployeeCodeDuplicates(allRows);
                if (duplicates.length) {
                    msgAlert('danger', `You have duplicate codes: ${duplicates} ,Please fix the problem and upload it again.`)
                } else {
                    let result = await confirm({
                        title: <>Warning</>,
                        message: t("r&r.uploadfile"),
                        confirmText: "Confirm",
                        confirmColor: "primary",
                        cancelColor: "link text-danger",
                    });
                    if (result)
                        dispatch(addEmployeeCodeSheet({ data: allRows.filter((el) => el.name && el.code), alertFun: msgAlert, t, file: files }));

                    setData(allRows.filter((el) => el.name && el.code));
                }
            };
        }
    }

    useEffect(() => {
        dispatch(getEmployeeSheets());
    }, []);

    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <SimpleHeader name={t("idea.ideas")} parentName={t("idea.ideasManagement")} />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">{t("r&r.uploadYourFile")}</h3>
                            </CardHeader>
                            <CardBody className={style.cardBody}>
                                <input type="file" accept=".xls,.xlsx" onChange={handleFileChange} />
                                <Button type="button" color="primary" onClick={onUpload} disabled={!files.name}>{t("r&r.upload")}</Button>
                                <Button type="button" color="primary" onClick={() => downloadFile(API_URLS.File.download + sheet[0]?.fileId[0], 'Milano Employee Codes')} >{t("download")}</Button>
                            </CardBody>
                        </Card>
                    </div >
                </Row >
            </Container >
        </>
    );
};
export default UploadEmployeeCodeSheet;